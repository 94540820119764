<template>
  <section :class="['change_password', type]">
    <el-form 
      :model="formData" 
      :rules="rules" 
      ref="form" 
      label-width="110px" 
      class="demo-ruleForm"
    >
      <el-form-item label="手机号码" prop="mobile">
        <el-input v-model="formData.mobile"></el-input>
      </el-form-item>
      <el-form-item prop="password" label="新密码">
        <el-input type="password" v-model="formData.password" placeholder="新密码">
        </el-input>
      </el-form-item>
      <el-form-item prop="rePassword" label="再次输入密码">
					<el-input type="password" v-model="formData.rePassword" placeholder="再次输入密码">
					</el-input>
				</el-form-item>

      <el-form-item prop="code" label="验证码">
        <el-input placeholder="验证码" v-model="formData.code" @keyup.enter="submitForm()">
        </el-input>
        <el-button 
          class="input_after"
          :disabled="second<$options.data().second" 
          @click="sendCode('resetpwd')"
        >{{ second >= $options.data().second && '发送验证码' || second }}</el-button>
      </el-form-item>
    </el-form>

    <el-button 
      class="submit" 
      type="primary" 
      @click="submitForm()"
    >重置</el-button>
  </section>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'mt80'
    },
    showBtn: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      formData: {},
      // 校验规则
			rules: {
				password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur"
				  },
          {
						min: 8,
						max: 16,
						message: '请输入8-16位密码',
						trigger: 'blur'
					},
        ],
        rePassword: [
          {
            required: true,
            message: "请再次输入密码",
            trigger: "blur"
				  },
          { validator: (rule, value, callback) => {
            // if (value === '') {
            //   callback(new Error('请再次输入密码'))
            // } else 
            if (value !== this.formData.password) {
              callback(new Error('两次密码不一致'))
            } else {
              callback()
            }
          }, 
          trigger: 'blur' }
        ],
				mobile: [
					{
						required: true,
						message: "请输入手机号",
						trigger: "blur"
					},
					{
						min: 11,
						max: 11,
						message: '请输入11位手机号码',
						trigger: 'blur'
					},
          {
            pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: '请输入正确的手机号码'
					}
				],
				code: [
					{
						required: true,
						message: "请输入验证码",
						trigger: "blur"
					},
					{
						min: 4,
						max: 4,
						message: '请输入4位验证码',
						trigger: 'blur'
					},
          {
            pattern: /^[0-9]{4}$/,
            message: '请输入正确的验证码'
					}
				],
			},
      // 手机验证码得计时器
			second: 60,
			timer: null,
    };
  },

  components: {},

  computed: {},

  methods: {
    // 提交校验
		submitForm() {
			this.$refs.form.validate(valid => {
				if (valid) {
					this.resetPassword()
				} else {
					this.$message.error("请填写完整");
					return false;
				}
			});
		},
    // 获取手机验证码
		async sendCode(event=''){
			const checkPhone=await this.checkPhone()
			if (!checkPhone) return
			const res=await this.$api.sendCode({
				mobile: this.formData.mobile,
				event
			})
			this.setSendCodeTimer()
			// if (res) {
			// }
		},
    // 检测手机号
		checkPhone(){
			return new Promise(resolve=>{
				this.$refs.form.validateField('mobile', (e)=>{
					if (!e) {
						resolve(true)
					} else {
						this.$message.error('须正确填写手机')
						resolve(false)
					}
				})
			})
		},
    // 发送手机验证码
		setSendCodeTimer(){
			clearInterval(this.timer)
			this.timer=setInterval(() => {
				if (this.second <= 0)	{
					clearInterval(this.timer)
					this.second=this.$options.data().second
					return
				}
				this.second--
			}, 1000);
		},

    // 重置密码
		async resetPassword(){
			const res=await this.$api.resetPassword(this.formData)
		},
  },

  mounted() {}
}

</script>
<style lang='scss' scoped>
.change_password{
  ::v-deep .el-form-item{
		.el-form-item__content{
			display: flex;

			.input_after{
				margin-left: 5px;
			}
		}
	}

  .submit {
		width: 100%;
		height: 36px;
		margin-top: 20px;
	}
}
.mt80{
  margin-top: 80px;
}
</style>