<template>
	<div class="login-wrap">
		<div class="ms-login">
			<div class="ms-title">
				<span>易债网</span>
				<span class="type">登录</span>
			</div>

			<!-- 账号登录 -->
			<el-form
				v-if="!isPhoneLogin"
				:model="params" 
				:rules="rules" 
				ref="login" 
				label-width="0px" 
				class="ms-content"
			>
				<el-form-item prop="account">
					<el-input v-model="params.account" placeholder="account">
						<template #prepend>
							<el-button icon="el-icon-user"></el-button>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item prop="password">
					<el-input type="password" placeholder="password" v-model="params.password" @keyup.enter="submitForm()">
						<template #prepend>
							<el-button icon="el-icon-lock"></el-button>
						</template>
					</el-input>
				</el-form-item>
			</el-form>

			<!-- 手机验证码登录 -->
			<el-form 
				v-else
				:model="params" 
				:rules="rules" 
				ref="login" 
				label-width="0px" 
				class="ms-content"
			>
				<el-form-item prop="mobile">
					<el-input v-model="params.mobile" placeholder="mobile">
						<template #prepend>
							<el-button icon="el-icon-phone"></el-button>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item prop="code">
					<el-input placeholder="code" v-model="params.code" @keyup.enter="submitForm()">
						<template #prepend>
							<el-button icon="el-icon-lock"></el-button>
						</template>
					</el-input>
					<el-button 
						class="input_after"
						:disabled="second<$options.data().second" 
						@click="sendCode('mobilelogin')"
					>{{ second >= $options.data().second && '发送验证码' || second }}</el-button>
				</el-form-item>
			</el-form>

			<div class="login-btn">
				<div class="set">
					<el-button 
						type="text" 
						@click="changeModel"
					>{{ isPhoneLogin && '账户密码登录' || '手机号登录' }}</el-button>

					<el-checkbox v-if="!isPhoneLogin" v-model="rememberPW">记住密码</el-checkbox>
				</div>
				<el-button class="submit" type="primary" @click="submitForm()">登录</el-button>
				<div class="other">
					<el-button type="text" @click="$router.push('/registered')">注册</el-button>
					<el-button type="text" @click="resetPw=true">忘记密码</el-button>
				</div>
			</div>
		</div>

		<el-dialog
			title="重置密码"
			v-model="resetPw"
		>
			<changePassword type="mt0"></changePassword>	
		</el-dialog>
	</div>
</template>

<script>
import utils from '@/utils'
import changePassword from '../modules/myHome/components/changePassword.vue'
const { cookie, saveUserInfo } = utils
export default {
	components: {
		changePassword
	},
	data() {
		return {
			resetPw: false,
			// 登录参数
			params: {
				account: '',
				password: '',
				mobile: '',
				code: ''
			},
			// 校验规则
			rules: {
				account: [{
					required: true,
					message: "请输入用户名",
					trigger: "blur"
				}],
				password: [{
					required: true,
					message: "请输入密码",
					trigger: "blur"
				}],
				mobile: [
					{
						required: true,
						message: "请输入手机号",
						trigger: "blur"
					},
					{
						min: 11,
						max: 11,
						message: '请输入11位手机号码',
						trigger: 'blur'
					},
          {
            pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: '请输入正确的手机号码'
					}
				],
				code: [
					{
						required: true,
						message: "请输入验证码",
						trigger: "blur"
					},
					{
						min: 4,
						max: 4,
						message: '请输入4位验证码',
						trigger: 'blur'
					},
          {
            pattern: /^[0-9]{4}$/,
            message: '请输入正确的验证码'
					}
				],
			},
			rememberPW: JSON.parse(cookie.getCookie('rememberPW')) || false, // 是否记住密码
			model: 'account',  // account账号模式  phone手机号模式
			second: 60,
			timer: null,
		};
	},
	created() {
		this.$store.commit("clearTags");
		this.init()
	},
	methods: {
		init(){
			// this.rememberPW=JSON.parse(cookie.getCookie('rememberPW'))
			if (this.rememberPW) {
				this.getPassword()
			}
		},

		// 提交校验
		submitForm() {
			this.$refs.login.validate(valid => {
				if (valid) {
					if (this.isPhoneLogin) {
						this.mobilelogin()
					} else {
						this.login()
					}
				} else {
					this.$message.error("请填写完整再尝试登录");
					return false;
				}
			});
		},

		// 登录接口
		async login(){
			const res=await this.$api.login(this.params)
			if (res) {
				// 保存
				const { userinfo } = res
				const { token, expires_in, id } =userinfo
				cookie.setCookie('uid', id, 100)
				cookie.setCookie('token', token, 100)
				cookie.setCookie('refreshTime', new Date().valueOf(), expires_in / 60 / 60 / 24 )

				saveUserInfo(userinfo)
				this.go()
			}
		},

		// 登录接口
		async mobilelogin(){
			const { mobile, code } = this.params
			const res=await this.$api.mobilelogin({
				mobile: mobile,
				captcha: code
			})
			if (res) {
				// 保存
				const { userinfo } = res
				const { token, expires_in, id } =userinfo
				cookie.setCookie('uid', id, 100)
				cookie.setCookie('token', token, 100)
				cookie.setCookie('refreshTime', new Date().valueOf(), expires_in / 60 / 60 / 24 )

				saveUserInfo(userinfo)
				this.go()
			}
		},

		// 跳转
		go(){
			const redirect=this.$route.query.redirect
			delete this.$route.query.redirect
			if (redirect) {
				this.$router.push({
					path: redirect,
					query: this.$route.query
				})
			} else {
				this.$router.push({
					path: '/',
					query: this.$route.query
				})
			}
		},

		// 获取验证码
		async sendCode(event=''){
			const checkPhone=await this.checkPhone()
			if (!checkPhone) return
			const res=await this.$api.sendCode({
				mobile: this.params.mobile,
				event
			})
			this.setSendCodeTimer()
			// if (res) {
			// }
		},

		// 检测手机号
		checkPhone(){
			return new Promise(resolve=>{
				this.$refs.login.validateField('mobile', (e)=>{
					if (!e) {
						resolve(true)
					} else {
						resolve(false)
					}
				})
			})
		},

		//	切换模式
		changeModel(){
			const model={
				'mobile': 'account',
				'account': 'mobile'
			}
			this.model=model[this.model]
		},
		
		// 发送验证码
		setSendCodeTimer(){
			clearInterval(this.timer)
			this.timer=setInterval(() => {
				if (this.second <= 0)	{
					clearInterval(this.timer)
					this.second=this.$options.data().second
					return
				}
				this.second--
			}, 1000);
		},

		// 保存密码
		savePassword(){
			const { account, password } = this.params
			// 这里需要简单的加密
			cookie.setCookie('account', account)
			cookie.setCookie('password', password)
		},

		// 获取保存的账号密码
		getPassword(){
			Object.assign(this.params, {
				account: cookie.getCookie('account'),
				password: cookie.getCookie('password')
			})

		}
	},
	computed: {
		// 是否手机号登录
		isPhoneLogin(){
			return this.model=='mobile'
		},
	},
	watch: {
		rememberPW(){
			if(this.rememberPW) {
				cookie.setCookie('rememberPW', this.rememberPW)
				this.savePassword()
			} else {
				// 清除
				cookie.delCookie('account')
				cookie.delCookie('password')
				cookie.delCookie('rememberPW')
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.login-wrap {
	// position: relative;
	width: 100%;
	height: 100%;
	background-image: url(../../../public/img/login_bg.jpg);
	background-size: 100%;
	background-repeat: round;
}

.ms-title {
	width: 100%;
	line-height: 50px;
	text-align: center;
	font-size: 20px;
	// color: #000;
	border-bottom: 1px solid #ddd;
}

.ms-login {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 350px;
	margin: -190px 0 0 -175px;
	border-radius: 5px;
	background: rgba(255, 255, 255, 0.9);
	overflow: hidden;
}

.ms-content {
	padding: 30px 30px;
	.el-form-item:nth-child(2){
		margin-bottom: 10px;
	}
	::v-deep .el-form-item{
		.el-form-item__content{
			display: flex;

			.input_after{
				margin-left: 5px;
			}
		}
	}
}

.login-btn {
	text-align: center;
	padding: 0 30px;
	padding-bottom: 20px;
	.submit {
		width: 100%;
		height: 36px;
		margin-bottom: 10px;
	}

	.other{
		// margin-top: 10px;
		display: flex;
		justify-content: space-between;
		>*{
			padding: 0 10px;
			height: auto;
			min-height: auto;
			width: auto;
		}
		
	}
	::v-deep .set{
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
	 .el-button{
			// padding: 0 10px;
			min-height: auto;
			height: auto;
			width: auto;
		}
		.el-checkbox__label{
			color: #409EFF;
			line-height: 1;
		}
	}
	.el-button--text{
		padding: 0;
	}
}



.login-tips {
	font-size: 12px;
	line-height: 30px;
	color: #fff;
}



</style>
